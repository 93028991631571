import React from 'react'
import { Slide } from "react-awesome-reveal";
import SectionTitle from '../SectionTitle'
import sImg1 from '../../images/event/1.jpg'
import sImg2 from '../../images/event/2.jpg'
import festa from '../../images/event/festa.mp4'

const Events = [
    {
        Simg: sImg1,
        title: 'O que vestir?',
        li1: 'Esporte fino / Social',
        animation:'1200',
        link: 'https://br.pinterest.com/search/pins/?q=looks%20esporte%20fino%20ou%20social%20para%20casamento&rs=typed',
        linkTitle: 'Inspirações',
        target: "_blank",
    },
    {
        Simg: sImg2,
        title: 'E a marcha nupcial?',
        li1: 'O reverendo João Bosco ditará o rítimo.',
        li2: 'Das 18hrs até as 19hrs',
        linkTitle: 'Ópera de Bambu',
        link: '/sobre-o-local',
        animation:'1400',
    },
    {
        title: 'E a festa?',
        li1: 'Logo após o jantar partiu comemorar.',
        li2: 'Quem pedir macarena será expulso do local!',
        li3: 'Até meia noite, por questões políticas. :/',
        link: '/sobre-o-local',
        linkTitle: 'Ópera de Bambu',
        animation:'1600',
        video: festa
    },
]

const EventSection = (props) => {
    return (
        <section className="wpo-event-section section-padding" id="event">
            <div className="container">
                <SectionTitle subTitle={'O grande dia'} MainTitle={'E agora?'} />
                <div className="wpo-event-wrap">
                    <div className="row">
                        {Events.map((event, eitem) => (
                            <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                                <Slide direction="up" duration={event.animation} triggerOnce="true">
                                    <div className="wpo-event-item">
                                        <div className="wpo-event-img">
                                            <div className="wpo-event-img-inner">
                                                <img src={event.Simg} alt="" />
                                                {event.video ? <video playsInline width="300" height="300" autoPlay muted poster={event.video} loop>
                                                    <source src={event.video} type='video/mp4'/>
                                                </video> : ""}
                                            </div>
                                        </div>
                                        <div className="wpo-event-text">
                                            <div className="title">
                                                <h2>{event.title}</h2>
                                            </div>
                                            <ul>
                                                <li>{event.li1}</li>
                                                <li>{event.li2}</li>
                                                <li>{event.li3}</li>
                                                <li><a target={event.target} rel={event.rel} href={event.link}>{event.linkTitle}</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </Slide>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default EventSection;