import React from 'react';
import { Link } from 'react-router-dom'

const Contactpage = () => {

    return(
        <section className="wpo-contact-pg-section section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-10 offset-lg-4">
                        <div className="office-info">
                            <div className="row">
                                <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                                    <div className="office-info-item">
                                        <div className="office-info-icon">
                                            <div className="icon">
                                                <i className="fi flaticon-maps-and-flags"></i>
                                            </div>
                                        </div>
                                        <div className="office-info-text">
                                            <h2>Endereço</h2>
                                            <p>R. Nestor Victor, 434 - Água Verde, Curitiba - PR</p>
                                            <p><a target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=Ópera+de+Bambu+-+Rua+Nestor+Victor+-+Água+Verde,+Curitiba+-+PR&travelmode=driving">Como chegar</a></p>
                                        </div>
                                        <div className="social">
                                            <ul>
                                                <li><a target="_blank" href="https://www.instagram.com/operadebambu"><i className="ti-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
                </div>
            </div> 
            <section className="wpo-contact-map-section">
                <div className="wpo-contact-map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3602.49083894488!2d-49.2769548!3d-25.4552807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94dce486f0ee9a13%3A0x14829a24bd9fcdc5!2sRua%20Nestor%20Victor%2C%20434%20-%20%C3%81gua%20Verde%2C%20Curitiba%20-%20PR%2C%2080620-400!5e0!3m2!1spt-BR!2sbr!4v1718143355505!5m2!1spt-BR!2sbr"></iframe>
                </div>
            </section>
        </section>
     )
        
}

export default Contactpage;
