import React, {Fragment, useState} from 'react';
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {totalPrice, formatMoney} from "../../utils";
import './style.scss';
import PixCode from './pixCode';
import { Payment, initMercadoPago, StatusScreen } from '@mercadopago/sdk-react';

const CheckoutSection = ({cartList}) => {
    let publicKey = 'APP_USR-a535f9fe-95cd-439b-a643-d4225e26adc6';
    let urlApi = "https://api.olucaseafernanda.com.br/process_payment"
    if (window.location.href.indexOf('localhost') > 0) {
        publicKey = 'TEST-869ce0c1-12aa-47a2-b9f5-6a4cfb08594e';
        //urlApi = "http://localhost:3000/process_payment"
    }
    initMercadoPago(publicKey, { locale: 'pt' });

    const initialization = {
        amount: totalPrice(cartList),
        preferenceId: "<PREFERENCE_ID>",
    };

    const customization = {
        paymentMethods: {
            creditCard: "all",
        },
    };

    const [paymentStatus, setPaymentStatus] = useState(null);
    const [paymentData, setPaymentData] = useState(null);
    const [customizationData, setcustomizationData] = useState(null);

    const onSubmit = async ({ selectedPaymentMethod, formData }) => {
        formData.transaction_amount = totalPrice(cartList);
        return new Promise((resolve, reject) => {
            fetch(urlApi, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData)
            })
                .then((response) => response.json())
                .then((response) => {
                    setPaymentData({paymentId:response.id});
                    setcustomizationData({
                        visual: {
                            hideStatusDetails: true,
                            hideTransactionDate: true,
                          },
                        backUrls: {
                          error: 'https://www.olucaseafernanda.com.br/pagamento',
                          return: 'https://www.olucaseafernanda.com.br'
                        }
                      },)
                    setPaymentStatus(response.status);
                    resolve();
                })
                .catch((error) => {
                    // Lidar com a resposta de erro ao tentar criar o pagamento
                    console.error('Erro ao processar pagamento:', error);
                    setPaymentStatus('error');
                    reject();
                });
        });
    };

    const onError = async (error) => {
        // Callback chamado para todos os casos de erro do Brick
        console.log(error);
    };

    const onReady = async () => {
        // Callback chamado quando o Brick estiver pronto.
    };

    return (
        <Fragment>
            <Grid className="checkoutWrapper section-padding">
                <Grid className="container" container spacing={3}>
                    {paymentStatus === null ? (
                        <>
                            <Grid item md={6} xs={12}>
                                <Grid className="cartStatus">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <Grid className="cartTotals">
                                                <h4>Total do Carrinho</h4>
                                                <Table>
                                                    <TableBody>
                                                        {cartList?.map(item => (
                                                            <TableRow key={item.id}>
                                                                <TableCell>{item.title} {formatMoney(item.price)} x {item.qty}</TableCell>
                                                                <TableCell align="right">{formatMoney(item.qty * item.price)}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                        <TableRow className="totalProduct">
                                                            <TableCell>Total Item</TableCell>
                                                            <TableCell align="right">{cartList.length}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell>Preço Total</TableCell>
                                                            <TableCell align="right">{formatMoney(totalPrice(cartList))}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Payment
                                    initialization={initialization}
                                    customization={customization}
                                    onSubmit={onSubmit}
                                    onReady={onReady}
                                    onError={onError}
                                />
                            </Grid>
                            <PixCode />
                        </>
                    ) : (
                        <Grid item xs={12}>
                            <StatusScreen
                                initialization={paymentData}
                                onReady={onReady}
                                onError={onError}
                                locale='pt-BR'
                            />
                        </Grid>
                    )}
                </Grid>
            </Grid>
            
        </Fragment>
    );
};

export default CheckoutSection;