import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import Footer from '../../components/footer/Footer'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Contactpage from '../../components/Contactpage/Contactpage';
import PortfolioSectionS2 from '../../components/PortfolioSectionS2/PortfolioSectionS2';


const ContactPage =() => {
    return(
        <Fragment>
            <Navbar hclass={'wpo-header-style-3'}/>
            <PortfolioSectionS2 prClass={'pb-0 pt-120'}/>
            <Contactpage/> 
            <Footer footerClass={'wpo-site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};

export default ContactPage;

