import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Snackbar, Typography, Container, Box } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import qrCodeImage from '../../images/pix/qrcode-pix.png'; // ajuste o caminho conforme necessário

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PixCode = (text) => {
  const [copied, setCopied] = useState(false);
  const pixCode = '00020126460014BR.GOV.BCB.PIX0124olucascarvalho@gmail.com5204000053039865802BR5914Lucas Carvalho6008Curitiba62070503***63045934';

  const handleCopy = () => {
    setCopied(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setCopied(false);
  };

  return (
    <Container maxWidth="sm" style={{  }}>
      <Typography variant="h5" gutterBottom>
        PIX
      </Typography>
      <Box mb={2}>
        <Typography variant="body1" style={{ wordWrap: 'break-word' }}>
          {pixCode}
        </Typography>
      </Box>
      
      <CopyToClipboard text={pixCode} onCopy={handleCopy}>
        <Button variant="contained" color="primary">
          PIX Copia e cola
        </Button>
      </CopyToClipboard>
      <Snackbar open={copied} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Código copiado!
        </Alert>
      </Snackbar>
      <Box mb={2}>
        <img src={qrCodeImage} alt="QR Code do PIX" style={{ width: '150', height:'150' }} />
      </Box>
    </Container>
  );
};

export default PixCode;

