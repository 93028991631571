import React from 'react'
import sImg1 from '../../images/story/1.png'
import sImg2 from '../../images/story/2.mp4'
import sImg3 from '../../images/story/3.png'

import shape1 from '../../images/story/s-shape3.png'
import shape2 from '../../images/story/s-shape4.png'
import shape3 from '../../images/story/s-shape5.png'
import SectionTitle from '../SectionTitle'


const StorySection5 = (props) => {
    return (

        <section className="wpo-story-section-s5 section-padding" id="story">
            <div className="container">
                <SectionTitle subTitle={'Nossa História'} MainTitle={'Como aconteceu?'} />
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="story-timeline">
                            <div className="round-shape"></div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder right-align-text left-site">
                                        <img src={sImg1} alt="" className="img img-responsive" />
                                        <div className="story-shape-img-1">
                                            <img src={shape1} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <span className="date">28 Dez, 2018</span>
                                        <h3>Como nos conhecemos</h3>
                                        <p>Uma viagem de final de ano fez com que nós dois nos encontrássemos. E logo que o Lucas viu sua sereia pela primeira vez, foi amor à primeira vista, sem clichês.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12 order-lg-1 order-2 text-holder right-heart">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text right-align-text">
                                        <span className="date">28 Dez, 2021</span>
                                        <h3>O pedido, ela disse sim!</h3>
                                        <p>Como não poderia ser diferente, o pedido aconteceu no mesmo local onde nos conhecemos, na ilha do mel, no mesmo dia 28.</p>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 order-lg-2 order-1">
                                    <div className="img-holder right-align-img">
                                    <video playsInline width="350" height="490" autoPlay muted poster={sImg2} loop>
                                        <source
                                            src={sImg2}
                                            type="video/mp4"
                                        />
                                    </video>
                                        <div className="story-shape-img-1">
                                            <img src={shape2} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col col-lg-6 col-12">
                                    <div className="img-holder video-holder left-site">
                                        <img src={sImg3} alt="" className="img img-responsive" />
                                        <div className="story-shape-img-1">
                                            <img src={shape3} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col col-lg-6 col-12 text-holder">
                                    <span className="heart">
                                        <i className="fi flaticon-heart"></i>
                                    </span>
                                    <div className="story-text">
                                        <span className="date"> 28 Fev, 2022</span>
                                        <h3>Juntando as escovas de dentes</h3>
                                        <p>Tendo como condição ter dois gatos, Fernanda se juntou com Lucas e os dois compartilham o mesmo CEP até hoje.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col offset-lg-6 col-lg-6 col-12 text-holder">
                                    <div className="round-shape-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default StorySection5;